import React, { CSSProperties, useCallback, useRef, useState } from 'react';
import { Heading } from '@brainstud/coach-panel/Layouts';
import { Button } from '@brainstud/ui/Buttons/Button';
import { useOnClickOutside } from '@brainstud/universal-components/Hooks/useOnClickOutside';
import { Close } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { FilterButton } from '../FilterButton';
import styles from './FilterSidebarButton.module.css';

const cx = classNames.bind(styles);

type TProps = {
  label?: string;
  title?: string;
  onReset?: () => void;
  count?: number;
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
};

/**
 * FilterSidebarButton.
 *
 * With this component you can add a button that will toggle a sidebar onClick.
 * The contents of the sidebar can be added as children.
 */
export const FilterSidebarButton = ({
  label,
  title,
  children,
  onReset,
  count = 0,
  className,
  style,
}: TProps) => {
  const [t] = useTranslator();

  // Logic for handling opening / closing of filter panel
  const [openPanel, setOpenPanel] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useOnClickOutside([sidebarRef, buttonRef], () => {
    setOpenPanel(false);
  });

  const handleTogglePanel = useCallback(() => {
    setOpenPanel((prevState) => !prevState);
  }, []);

  return (
    <div className={cx(styles.base, className)} style={style}>
      <FilterButton
        ref={buttonRef}
        onClick={handleTogglePanel}
        active={openPanel}
        count={count}
      >
        {label || t('components.filter_buttons.label')}
      </FilterButton>

      {openPanel && (
        <div
          ref={sidebarRef}
          className={cx(styles.sidebar, 'filter-sidebar--container')}
        >
          <Heading className={cx(styles.heading)}>
            {title && <h3>{title}</h3>}
            {onReset && (
              <Button
                type="button"
                link
                className={cx(styles.reset)}
                onClick={onReset}
              >
                {t('components.filter_buttons.reset')}
              </Button>
            )}
            <button
              type="button"
              onClick={handleTogglePanel}
              className={cx(styles.close)}
            >
              <Close />
            </button>
          </Heading>
          <div className={cx(styles.inner, 'filter-sidebar--content')}>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};
