import React, { CSSProperties, MouseEventHandler, useCallback } from 'react';
import { Badge } from '@brainstud/ui/Static/Badge';
import { FilterList } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { usePlausibleTracking } from 'Hooks/usePlausible';
import { useRouter } from 'next/router';
import styles from './FilterButton.module.css';

const classes = classNames.bind(styles);

export type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  /** Shows number within the button */
  count?: number;
  /** When the filtering is activated */
  active?: boolean;
  children: string;
  style?: CSSProperties;
  className?: string;
};

/**
 * FilterButton.
 *
 * Shows a button in filter styling. Either to open a panel or drawer to select filtering options.
 */
export const FilterButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ onClick, active, count = 0, style, className, children }: Props, ref) => {
    const plausible = usePlausibleTracking();
    const router = useRouter();
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
      (event) => {
        plausible('UX:filter_button:click', {
          page: router.pathname,
        });
        onClick(event);
      },
      [plausible, router.pathname, onClick]
    );

    return (
      <button
        type="button"
        className={classes(styles.base, { active }, className)}
        onClick={handleClick}
        ref={ref}
        style={style}
      >
        <FilterList className={classes(styles.icon)} />

        <span className={classes(styles.text)}>{children}</span>

        {count > 0 && (
          <Badge className={classes(styles.badge)} circle>
            {count}
          </Badge>
        )}
      </button>
    );
  }
);
