import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useEnrollmentLearningObjectCollection } from '@brainstud/academy-api/Hooks/useEnrollmentLearningObjectCollection';
import { useEnrollment } from '@brainstud/academy-api/Hooks/useEnrollments';
import { useStudent } from '@brainstud/academy-api/Hooks/useStudents';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown';
import { CheckCircle, InfoOutlined } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { CourseCompletionBanner } from 'Components/CourseCompletionBanner';
import { Link } from 'Components/Link';
import { Tabs, TabsItem } from 'Components/Tabs';
import { CourseCompletionModal } from 'Modals';
import { StudentDetailModal } from 'Modals/StudentDetailModal/StudentDetailModal';
import { useRouter } from 'next/router';
import { useBreadcrumbs } from 'Providers/BreadcrumbsProvider/useBreadcrumbs';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import styles from './StudentNavigation.module.css';

const cx = classNames.bind(styles);

const StudentNavigation = () => {
  const [t] = useTranslator();
  const router = useRouter();
  const slug = router.asPath.split('/').pop();
  const { openModal } = useModals();

  const { studentId, enrollmentId } = router.query as {
    studentId: string;
    enrollmentId: string;
  };
  const [selectedEnrollmentId, setSelectedEnrollmentId] =
    useState<string>(enrollmentId);

  const [{ data: student }] = useStudent({
    student: studentId,
    include: ['enrollments'],
  });

  const defaultEnrollmentId = useMemo(
    () => enrollmentId || student?.enrollments?.()?.[0]?.id || false,
    [enrollmentId, student]
  );

  const handleEnrollment = (value: string) => {
    setSelectedEnrollmentId(value);
    router.push(`/coach/students/${studentId}/enrollment/${value}/${slug}`);
  };

  const routeMatch = router.asPath === '/coach/students/:studentId';
  useEffect(() => {
    if (routeMatch && defaultEnrollmentId) {
      router.replace(
        `/coach/students/${studentId}/enrollment/${defaultEnrollmentId}/portfolio`
      );
    }
  }, [routeMatch, defaultEnrollmentId, router, studentId]);

  const [{ data: enrollment }, { isLoading }] = useEnrollment(
    {
      enrollment: enrollmentId?.toString(),
    },
    { enabled: !!enrollmentId }
  );

  const [{ data: collection }] = useEnrollmentLearningObjectCollection({
    enrollment: enrollmentId,
    learningObjectCollection: enrollment?.learningObjectCollection?.().id,
  });
  const learningObjects = useMemo(
    () => collection?.learningObjects?.() || [],
    [collection]
  );
  const currentCourseTitle = enrollment?.course?.().title;
  const hasExam = useMemo(
    () => enrollment?.course?.().examsEnabled || enrollment?.exam?.(),
    [enrollment]
  );

  const hasProgress = useMemo(
    () =>
      `(${learningObjects.filter((learningObject) => learningObject.progress).length}/${learningObjects.length})`,
    [learningObjects]
  );

  useBreadcrumbs(
    {
      ...(student && { [student.id]: { text: student.fullName, href: null } }),
      [enrollmentId]: { text: currentCourseTitle, href: 'portfolio' },
    },
    [student, enrollment]
  );

  const studentFullName =
    student && [student.firstName, student.fullLastName].join(' ');

  const openCourseCompletionModal = useCallback(() => {
    openModal(CourseCompletionModal, {
      studentFullName,
      courseTitle: currentCourseTitle,
      isComplete: enrollment?.isCompleted,
    });
  }, [currentCourseTitle, enrollment, openModal, studentFullName]);

  return (
    <>
      <header className={cx(styles.root)}>
        <Container>
          <div className={cx(styles.flexHeader)}>
            <h2 className={cx(styles.title)}>{studentFullName}</h2>
            {student && (
              <Button
                className={cx(styles.infoButton)}
                quiet
                small
                onClick={() => {
                  openModal(StudentDetailModal, { studentId, enrollmentId });
                }}
              >
                <InfoOutlined fontSize="large" />
              </Button>
            )}
          </div>
          <div className={cx(styles.enrollmentNav)}>
            {student && (
              <>
                <Dropdown
                  onChange={(value) => value && handleEnrollment(value)}
                  loading={enrollmentId !== selectedEnrollmentId}
                  value={selectedEnrollmentId || enrollmentId}
                >
                  {student.enrollments?.().map((studentEnrollment) => (
                    <Dropdown.Option
                      key={studentEnrollment.id}
                      value={studentEnrollment.id}
                    >
                      <div className={cx(styles.enrollmentItems)}>
                        {studentEnrollment.course?.().title}
                        {studentEnrollment?.isCompleted && <CheckCircle />}
                      </div>
                    </Dropdown.Option>
                  ))}
                </Dropdown>
                {!enrollment?.isCompleted && !hasExam && !isLoading && (
                  <Button
                    type="button"
                    outline
                    className={cx(styles.completeCourseButton)}
                    onClick={openCourseCompletionModal}
                  >
                    <CheckCircle fontSize="large" />
                    <span>{t('navigation.student.complete_course')}</span>
                  </Button>
                )}
              </>
            )}
          </div>
          <Tabs>
            <TabsItem
              component={Link}
              href={`/coach/students/${studentId}/enrollment/${enrollmentId}/portfolio`}
              label={`${t('navigation.student.portfolio')} ${hasProgress && hasProgress}`}
            />
            {hasExam && (
              <TabsItem
                component={Link}
                href={`/coach/students/${studentId}/enrollment/${enrollmentId}/exam`}
                label={t('navigation.student.exam')}
              />
            )}
            <TabsItem
              component={Link}
              href={`/coach/students/${studentId}/enrollment/${enrollmentId}/details`}
              label={t('navigation.student.settings')}
              className={cx(styles.tabRight)}
            />
          </Tabs>
        </Container>
      </header>
      {enrollment?.isCompleted && (
        <CourseCompletionBanner viewRating={openCourseCompletionModal} />
      )}
    </>
  );
};

export default StudentNavigation;
